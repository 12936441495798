import React, { SVGProps } from 'react';
import tw, { css, styled, theme } from 'twin.macro';

export const BackgroundSectionContainer = tw.div`absolute w-full h-full z-0`;
export const BackgroundSectionContainerBleed = tw.div`absolute w-full z-0 -mt-32 height[calc(100% + 8rem)] md:(-mt-48 height[calc(100% + 12rem)])`;
export const BackgroundSectionWrapper = tw.div`relative max-w-screen-2xl mx-auto`;
export const BackgroundSectionFadeX = styled.div(
  css`
    background-image: linear-gradient(
      to right,
      white 0%,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0) 90%,
      white 100%
    );
  `,
  tw`absolute h-full w-full inset-0 hidden xl:block`,
);

type BackgroundSectionColors = 'pink' | 'blue' | 'purple';

type BackgroundSectionProps = SVGProps<SVGSVGElement> &
  (
    | {
        color: BackgroundSectionColors;
        end?: string;
        start?: string;
      }
    | {
        color: 'custom';
        end: string;
        start: string;
      }
  );

const COLORS_FOR_COLOR = {
  pink: {
    start: theme`colors.pink-50`,
    end: theme`colors.pink-200`,
  },
  blue: {
    start: theme`colors.blue-50`,
    end: theme`colors.blue-200`,
  },
  purple: {
    start: theme`colors.purple-50`,
    end: theme`colors.purple-200`,
  },
};

function BackgroundSection({
  color,
  start,
  end,
  ...rest
}: BackgroundSectionProps) {
  const colors = color === 'custom' ? { start, end } : COLORS_FOR_COLOR[color];
  const colorId =
    color === 'custom' && start && end ? `${start}_${end}` : color;
  const fillId1 = `${colorId}_background_section_1`;
  const fillId2 = `${colorId}_background_section_2`;

  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tw="w-full h-full"
      {...rest}
    >
      <path
        d="M0 64.683c821.224 391.24 973.073-263.987 1785 0v887.879H0V64.683z"
        fill={`url(#${fillId1})`}
        fillOpacity={0.5}
      />
      <path
        d="M1785 132.139c-821.224 345.605-973.073-233.196-1785 0V953.73h1785V132.139z"
        fill={`url(#${fillId2})`}
      />
      <path
        transform="matrix(1 0 0 -1 0 955)"
        fill={`url(#${color}_paint2_linear)`}
        d="M0 0h1785v738H0z"
      />
      <defs>
        <linearGradient
          id={fillId1}
          x1={10.477}
          y1={309.307}
          x2={1785}
          y2={320.282}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.start} />
          <stop offset={1} stopColor={colors.end} />
        </linearGradient>
        <linearGradient
          id={fillId2}
          x1={1785}
          y1={337.412}
          x2={0}
          y2={337.412}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.end} />
          <stop offset={1} stopColor={colors.start} />
        </linearGradient>
        <linearGradient
          id={`${color}_paint2_linear`}
          x1={893}
          y1={0}
          x2={892}
          y2={411.533}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.156} stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default BackgroundSection;
