import React, { PropsWithChildren } from 'react';

import Footer from '../Footer';
import Header, { HeaderProps } from '../Header';

type LayoutFullWidthProps = PropsWithChildren<{
  headerProps?: HeaderProps;
}>;

const LayoutFullWidth = ({
  children,
  headerProps = {},
}: LayoutFullWidthProps) => (
  <div tw="flex flex-col justify-between min-h-screen">
    <Header {...headerProps} />
    {children}
    <Footer />
  </div>
);

export default LayoutFullWidth;
