import Head from 'next/head';
import React from 'react';

import { APP_STAGE_NAME } from '../constants';

type Props = {
  description?: string;
  title: string;
  imageUrl?: string;
};

// TODO add default description
const PageMeta = ({ description = '', imageUrl, title }: Props) => (
  <Head>
    <title>{`${title} · ${
      APP_STAGE_NAME ? `${APP_STAGE_NAME}:` : ''
    } Chartsy`}</title>
    <meta name="description" content={description} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:site_name" content="Chartsy" />
    {imageUrl && <meta property="og:image" content={imageUrl} />}
    {/* TODO
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:creator" content={config.social.twitter} />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    */}
  </Head>
);

export default PageMeta;
